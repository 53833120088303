@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body, html, #root{
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        overflow: hidden;
    }
}
.slick-dots{
    position: relative;
    bottom: 0px;
    padding: 16px 0px;
}
.slick-dots li button:before{
    color: #a8a8a8;
}
.slick-dots li.slick-active button:before{
    color: #FFFFFF;
}